import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import HomePage from "../components/Pages/HomePage/HomePage"
import Seo from "../components/seo"

import "../styles/styles.sass"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Fiorenzato La passione per l’innovazione, l’amore per il caffè" />
    <HomePage dataMain={data?.allStrapiProdotti?.nodes} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query HomePage {
    allStrapiProdotti(sort: { fields: published_at, order: ASC }) {
      nodes {
        titolo
        slug
        icona {
          url
        }
      }
    }
  }
`
