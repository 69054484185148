import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const HomePage = ({ dataMain }) => {
  return (
    <section className="home-wrapper">
      <div className="home-wrapper__products">
        <div className="container-fliud">
          <div className="row">
            <h1 className="h1">La nostra gamma</h1>
            {(dataMain || []).map((item, index) => {
              return (
                <div
                  key={`${item?.titolo}_${index}`}
                  className="col-xl-6 col-md-6 home__products-border"
                >
                  <Link to={item?.slug} className="products-link">
                    <img
                      src={`http://admin.fiorenzato.app${item?.icona?.url}`}
                      className="products-img"
                      alt={item?.titolo}
                    />
                    <span>{item?.titolo}</span>
                  </Link>
                </div>
              )
            })}
          </div>
          <div className="row">
            <a
              href="http://manuals.fiorenzatohome.com/allground"
              target="blank"
            >
              <StaticImage
                src="../../../images/ALLGROUND_DEF_rosa.jpg"
                className="banner"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

HomePage.propTypes = {
  dataMain: PropTypes.array,
}

export default HomePage
